@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --light-gray-colour: #eeeeee;
    --light-gray-border-colour: #d4d4d4;
    --dark-gray-colour: #545454;
    --black-colour: #111111;

    --login-border-radius: 8px;
    --field-border-radius: 5px;

    --default-font-family: "Rubik", sans-serif;
    --default-font-size: 16px;
    --default-line-height: 24px;

    --small_font_size: 14px;
    --medium_font_size: 18px;
    --large_font_size: 24px;
    --xlarge_font_size: 36px;

    --page_bgcolor: #236cc8;
    --page_color: #111111;

    --page_header_color: white;
    --page_header_bgcolor: #236cc8;
    --page_header_hover_color: #03a9f4;

    --page_footer_bgcolor: #0f2e57;
    --page_footer_color: #8da5c5;

    --link_color: #236cc8;
    --link_hover_color: #03a9f4;

    --icon_color: #236cc8;
    --icon_hover_color: #03a9f4;
    --del_icon_color: #FF0000;

    --list-bgcolor: white;
    --list-color: #236cc8;
    --list_selected_bgcolor: white;
    --list_selected_color: #236cc8;

    --btn_default_color: white;
    --btn_default_bgcolor: #236cc8;
    --btn_default_hover_color: white;
    --btn_default_hover_bgcolor: #0a9ceb;

    --btn_cancel_color: white;
    --btn_cancel_bgcolor: #236cc8;
    --btn_cancel_hover_color: white;
    --btn_cancel_hover_bgcolor: #0a9ceb;

    --btn_other_color: white;
    --btn_other_bgcolor: #236cc8;
    --btn_other_hover_color: white;
    --btn_other_hover_bgcolor: #0a9ceb;

    --widget_bgcolor: #fefefe;
    --widget_color: #111111;
    --widget_header_bgcolor: #eeeeee;
    --widget_header_color: #111111;
    --widget_border_color: #d4d4d4;
    --widget_border_radius: 5px;
    --widget_footer_bgcolor: #eeeeee;
    --widget_footer_color: #111111;

    --table_bgcolor: #ffffff;
    --table_color: var(--black-colour);
    --table_border_color: var(--light-gray-border-colour);
    --table_header_bgcolor: var(--light-gray-border-colour);
    --table_header_color: var(--black-colour);
    --table_selected_bgcolor: var(--light-gray-colour);
    --table_selected_color: var(--black-colour);
}

a {
    color: var(--link_color);
    text-decoration: none;
}

a:hover,
a:focus {
    color: var(--link_hover_color);
}

body {
    width: 100%;
    height: 100vh;
    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    font-weight: 400;
    line-height: var(--default-line-height);
    color: var(--black-colour);
    background-color: var(--light-gray-colour);
}

.header {
    position: relative;
    height: 2.5em;
    background-color: var(--page_header_bgcolor);
    color: var(--page_header_color);
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.footer {
    width: 100%;
    height: 2.5em;
    background-color: var(--page_footer_bgcolor);
    color: var(--page_footer_color);
}

.footer-container {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

nav {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background-color: var(--page_header_bgcolor);
    color: var(--page_header_color);
    width: 100%;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
    z-index: 999;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 1rem;
}

nav li {
    margin-bottom: 1rem;
    margin-left: 1rem;
    text-transform: uppercase;
}

nav li::after {
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.1);
    height: 2px;
    margin-left: -1rem;
    margin-top: 0.5rem;
}

nav a {
    color: var(--page_header_color);
    text-decoration: none;
}

nav a:hover,
nav a.selected {
    color: var(--page_header_hover_color);
}

.navbar-toggler {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.navbar-toggler:checked~nav {
    transform: scale(1, 1);
}

.navbar-hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navbar-hamburger span,
.navbar-hamburger span:before,
.navbar-hamburger span:after {
    display: block;
    background: var(--page_header_color);
    height: 2px;
    width: 2rem;
    border-radius: 2px;
    position: relative;
}

.navbar-hamburger span:before,
.navbar-hamburger span:after {
    content: "";
    position: absolute;
}

.navbar-hamburger span:before {
    bottom: 7px;
}

.navbar-hamburger span:after {
    top: 7px;
}

.navbar-brand,
.navbar-logo {
    text-align: left;
}
.navbar-brand{
    display: flex;
    align-items: center;
}

.navbar-logo {
    font-size: var(--xlarge_font_size);
}

.navbar-menu-sub {
    top: 2em;
    right: -0.5em;
    position: absolute;
    background-color: var(--page_header_bgcolor);
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
    z-index: 999;
}

.navbar-menu-sub li {
    text-transform: none;
}


.navbar-menu-sub ul,
.navbar-menu-sub ul li:after {
    all: unset;
}

.nav-dropdown {
    position: relative;
}

.navbar-menu-main .nav-dropdown:hover .navbar-menu-sub {
    transform: scale(1, 1);
}

.logo-title {
    color: var(--page_header_color);
    font-size: var(--default_font_size);
    display: block;
    width: 20em;
}

.logo {
    padding: 0.25rem;
}

.container {
    width: 100%;
    max-width: 1280px;
    height: calc(100vh - 5em);
    margin: 0 auto;
    padding: 1rem 0.25rem;
    background-color: white;
    overflow: auto;
}

.main-contents {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em
}

.main-contents.empty{
    justify-content: center;
}

.main-contents-heading {
    font-size: var(--large_font_size);
    font-weight: 300;
    text-transform: uppercase;
}

@media screen and (min-width: 1281px) {
    .navbar-hamburger {
        display: none;
    }

    .header {
        display: grid;
        width: 1280px;
        grid-template-columns: 1fr auto minmax(400px, 3fr) 1fr;
    }
    .navbar-nav .sm-only {
        display: none;
    }

    .navbar-brand {
        min-width: 100%;
        justify-content: flex-start;
        padding-left: 0.75rem;
        gap: 0.5em;
    }
    nav {
        position: relative;
        text-align: left;
        transition: none;
        transform: scale(1, 1);
        background: none;
        top: initial;
        left: initial;
        grid-column: 3 / 5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 0.5em;
    }

    nav ul {
        display: flex;
        border-top: none;
        padding-top: 0;
    }

    nav li {
        margin-left: 3rem;
        margin-bottom: 0;
    }

    nav li::after {
        display: none;
    }

    nav a {
        position: relative;
    }

    nav a::before,
    nav a.selected::before {
        content: "";
        display: block;
        height: 3px;
        background: var(--page_header_hover_color);
        position: absolute;
        top: -0.75rem;
        left: 0;
        right: 0;
        transform: scale(0, 1);
        transition: transform ease-in-out 250ms;
    }

    nav a:hover::before,
    nav a.selected::before {
        transform: scale(1, 1);
    }

    .navbar-menu-sub li {
        width: max-content;
        margin: unset;
        padding: 0.25em 0.5em;
    }

    .navbar-menu-sub a::before {
        display: none;
    }
}

/* Loading indicator */
.loader-wrapper {
    display: flex;
    align-items: center;
}

.loader-message {
    margin-right: 1rem;
}

.loader {
    border: 0.2rem solid var(--black-colour);
    /* Light grey */
    border-top: 0.2rem solid var(--dark-gray-colour);
    /* Blue */
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 1s linear infinite;
}

.dimmer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    opacity: 1;
    z-index: 9999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.widget {
    width: 99%;
    height: max-content;
    background-color: var(--widget_bgcolor);
    border: 2px solid var(--widget_border_color);
    border-radius: var(--widget_border_radius);
    color: var(--widget_color);
    margin: 0.5rem;
    overflow: hidden;
}

.widget-no-border {
    border: none;
}

.widget-header {
    width: 100%;
    background-color: var(--widget_header_bgcolor);
    border-bottom: 1px solid var(--widget_border_color);
    color: var(--widget_header_color);
}

.widget-body {
    display: flex;
    justify-content: space-between;
}

.widget-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.65rem;
    align-items: center;
    background-color: var(--widget_footer_bgcolor);
    color: var(--widget_footer_color);
}

.widget-footer .btn {
    margin: unset;
}

.widget-col {
    width: 49.6%;
}

.widget-col .widget-header:first-child {
    border-right: 1px solid var(--widget_border_color);
}

.widget-col .widget-header:last-child {
    border-left: 1px solid var(--widget_border_color);
}

.widget-col-header h2,
.widget-header h2 {
    display: flex;
    font-size: var(--medium_font_size);
    padding: 0.5rem;
    color: var(--widget_header_color);
    gap: 0.5em;
}

.widget-header h2>svg {
    font-size: var(--large_font_size);
    color: var(--widget_header_color);
}

.list-box {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    cursor: pointer;
}

.list-box.no-pointer {
    cursor: unset;
}

.list-box li {
    padding: 0.5rem;
    border-bottom: 1px solid var(--light-gray-border-colour);
    display: flex;
}

.list-box li>span {
    display: block;
    width: 100%;
}

.list-box li:last-child {
    border-bottom: none;
}

.list-box li:hover {
    background-color: var(--list_selected_bgcolor);
    color: var(--list_selected_color);
}

.list-box.no-pointer li:hover {
    background-color: unset;
    color: unset;
}

.data-grid {
    width: 100%;
    display: grid;
    text-align: left;
    background-color: var(--table_bgcolor);
    color: var(--table_color);
}

.data-grid.request-grid {
    grid-template-columns: repeat(8, minmax(max-content, auto));
}
.request-sub-grid {
    grid-template-columns: repeat(6, minmax(max-content, auto));
}
.data-grid.device-grid {
    grid-template-columns: repeat(9, minmax(max-content, auto));
}
.data-grid.top-device-grid {
    grid-template-columns: repeat(7, minmax(max-content, auto));
}
.data-grid.device-sub-grid {
    grid-template-columns: repeat(6, minmax(max-content, auto));
}

.data-grid.apps-grid {
    grid-template-columns: repeat(8, minmax(max-content, auto));
}

.data-grid.top-apps-grid {
    grid-template-columns: repeat(7, minmax(max-content, auto));
}
.data-grid.location-lookup-grid {
    font-size: var(--small_font_size);
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 2fr;
}
.data-grid-full-row{
    text-align: center;
    color: red;
    font-weight: 500;
}
.request-grid .data-grid-full-row{
    grid-column: 1/9;
}
.request-sub-grid .data-grid-full-row{
    grid-column: 1/7;
}
.device-grid .data-grid-full-row{
    grid-column: 1/10;
}
.top-device-grid  .data-grid-full-row{
    grid-column: 1/8;
}
.device-sub-grid .data-grid-full-row{
    grid-column: 1/7;
}
.apps-grid .data-grid-full-row{
    grid-column: 1/9;
}
.top-apps-grid .data-grid-full-row{
    grid-column: 1/8;
}

.data-subgrid-row{
    margin: 0.25em;
    margin-left: 1em;
}
.data-grid.device-grid .data-subgrid-row{
    grid-column: 1 / 10;
}
.data-grid.top-device-grid .data-subgrid-row {
    grid-column: 1 / 8;
}
.data-grid.apps-grid .data-subgrid-row {
    grid-column: 1 / 9;
}
.data-grid.top-apps-grid .data-subgrid-row {
    grid-column: 1 / 8;
}

.data-grid-row {
    width: 100%;
    line-height: 2rem;
    text-align: left;
    display: contents;
}

.lookup-grid-row>div,
.data-grid-row>div {
    border-bottom: 1px solid var(--table_border_color);
}
.lookup-grid-row {
    width: 100%;
    line-height: 1.25rem;
    text-align: left;
    display: contents;
    cursor: pointer;
}
.lookup-grid-row.selected > .data-grid-col,
.lookup-grid-row:hover > .data-grid-col {
    background-color: var(--table_selected_bgcolor);
    color: var(--table_selected_color);
}
.lookup-icon{
    color: var(--icon_color);
    cursor: pointer;
}
.lookup-grid-row:first-child>div {
    background-color: var(--table_header_bgcolor);
    color: var(--table_header_color);
    padding: 0.25rem;
    font-weight: bold;
}


.data-grid-row:first-child>div {
    background-color: var(--table_header_bgcolor);
    color: var(--table_header_color);
}

.data-grid-col {
    padding-left: 0.25em;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.data-grid-col svg{
    font-size: var(--large_font_size);
    color: var(--icon_color);
    cursor: pointer;
    padding-left: 0.25em;
}
.data-grid-col:hover svg{
    color: var(--icon_hover_color);
}

.data-grid-col.icon-disabled svg {
    color: var(--light-gray-border-colour);
    cursor: not-allowed;
}
.data-list-container ul li svg.icon-status-active,
.data-grid-col svg.icon-status-active{
    color: #03C03C;
}
.data-list-container ul li svg.icon-status-active:hover,
.data-grid-col svg.icon-status-active:hover {
    color: #006400;
}
.data-list-container ul li svg.icon-status-inactive,
.data-grid-col svg.icon-status-inactive{
    color: #CC0000;
}
.data-list-container ul li svg.icon-status-inactive:hover,
.data-grid-col svg.icon-status-inactive:hover{
    color: #800020;
}
.data-grid-col svg.icon-no-cursor {
    cursor: unset;
}
.icon-col {
    cursor: pointer;
    color: var(--icon_color)
}

.del-icon {
    color: var(--del_icon_color);
}

.data-list-container{
    width: 100%;    
    border-radius: 0.25em;
    border: 1px solid var(--light-gray-border-colour);
    padding: 0.5em;
    margin-bottom: 0.25em;
}
.data-list-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 1.75em;
    margin-bottom: 0.25em;
}
.data-list-header::after{
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    left:0;
    bottom: 0;
    background-color: var(--light-gray-border-colour);
}
.data-list-header > .btn-tiny {
    margin-top: -0.5em;
}
.data-list-title {
    font-size: var(--medium-font-size);
}
.data-list-container ul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    padding-top: 0.25em;
}
.data-list-container ul li{
    position: relative;
    display: flex;
    gap: 0.5em;
    list-style: none;
    height: 2em;
    padding: 0.25em;
    padding-left: 1.25em;
    border-radius: 0.25em;
    border: 1px solid var(--btn_default_bgcolor);
    align-items: center;
    overflow: hidden;
}
.data-list-container ul li::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 2em;
    width: 1em;
    background-color: var(--btn_default_bgcolor);
}

.data-list-container ul li svg{
    cursor: pointer;
}
.data-list-container ul li .inactive {
    color: var(--light-gray-colour);

}
@media screen and (min-width: 1281px) {
    .widget-small {
        width: 48.7%;
        height: max-content;
    }
}

.btn {
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    border-radius: var(--field-border-radius);
    cursor: pointer;
}
.btn-tiny {
    padding: 0.25em 0.5em;
    background-color: var(--btn_default_bgcolor);
    color: var(--btn_default_color);
    margin: unset;
}
.btn-with-icon {
    padding: 0.5em 1em;
    margin: unset;
    display: flex;
    gap: 1em;
}

.btn-medium {
    padding: 10px 40px;
    margin-bottom: 20px;
    border: none;
    border-radius: var(--field-border-radius);
    cursor: pointer;
}

.btn-fullwidth {
    width: 100%;
    height: 40px;
    margin: 0 auto 20px auto;
    border: none;
    border-radius: var(--field-border-radius);
    cursor: pointer;
}

.btn-default {
    background-color: var(--btn_default_bgcolor);
    font-size: var(--default-font-size);
    color: var(--btn_default_color);
}

.btn-default:hover {
    background-color: var(--btn_default_hover_bgcolor);
    color: var(--btn_default_hover_color);
}

.btn-default:disabled {
    background-color: var(--btn_default_hover_bgcolor);
    pointer-events: none;
}

.btn-cancel {
    background-color: var(--btn_cancel_bgcolor);
    color: var(--btn_cancel_color);
    font-size: var(--default_font_size);
}

.btn-cancel:hover {
    background-color: var(--btn_cancel_hover_bgcolor);
    color: var(--btn_cancel_hover_color);
}

.btn-other {
    background-color: var(--btn_other_bgcolor);
    color: var(--btn_other_color);
    font-size: var(--default_font_size);
}

.btn-other:hover {
    background-color: var(--btn_other_hover_bgcolor);
    color: var(--btn_other_hover_color);
}

.login-wrapper {
    width: 100%;
    height: calc(100vh - 30px);
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background-color: var(--page_bgcolor);
    background-image: var(--page_bgimage);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.panel {
    width: 100%;
    margin: 1em auto;
    display: flex;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--login-border-radius);
}

.dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-box,
.dialog-box {
    min-width: 450px;
    min-height: 100px;
    max-width: 95%;
    max-height: 95%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--login-border-radius);
    z-index: 99;
}

.alert-box {
    min-width: 100px;
    min-height: unset;
    max-height: unset;
}

.login-box {
    width: 400px;
    height: max-content;
    display: flex;
    justify-self: center;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--login-border-radius);
    z-index: 99;
} 

.dialog-heading {
    font-size: var(--medium-font-size);
    font-weight: 600;
    color: var(--page_header_color);
}

.alert-box-title,
.dialog-title {
    background-color: var(--page_header_bgcolor);
    color: var(--page_header_color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;
    padding: 0.25em 0.5em;
    border-top-right-radius: var(--field-border-radius);
    border-top-left-radius: var(--field-border-radius);
}

.alert-box-title h2,
.dialog-title h2 {
    font-size: var(--medium-font-size);
    font-weight: 300;
    text-transform: uppercase;
}

.dialog-close {
    cursor: pointer;
    font-size: var(--large_font_size);
    padding-left: 1rem;
    padding-top: 0.25rem;
}

.dialog-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
}
.alert-box-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
}
.login-body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.alert-box-footer,
.dialog-footer {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
}

.login-box-title {
    font-size: var(--medium-font-size);
    color: var(--icon_color);
    font-weight: 600;
    margin-bottom: 2rem;
}

.lookup-dialog{
    max-height: 50%;
}
.lookup-dialog .dialog-heading {
    font-size: var(--small_font_size);
}
.lookup-dialog .dialog-title{
    min-height: 25px;
}
.lookup-dialog .dialog-body {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 0.5rem;
}
.lookup-dialog .dialog-title h2 {
    font-size: var(--small_font_size);
}
.lookup-dialog .dialog-close {
    font-size: var(--small_font_size);
}
.search-box {
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0.5em;
}
.request-search-box{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1em;
    gap: 1em;
}
.request-search-box .btn {
    margin: unset;
}
.request-search-input{
    position: relative;
    min-width: 20em;
    flex: 1;
}
.request-search-input .search-box-icon{
    top: 0.36em;
    left: 0.30em;
}
.request-search-options {
    display: flex;
    gap: 1em;
    width: 20em;
}
.request-search-checkbox{
    display: flex;
    gap: 1em;
}
.search-box-icon {
    position: absolute;
    font-size: 1.5em;
    color: rgba(0, 0, 0, 0.2);
    top: 0.65em;
    left: 0.5em;
}

.request-box-input,
.search-box-input {
    width: 90%;
    height: 2.5em;
    border-radius: 2.5em;
    border: 1px solid var(--light-gray-border-colour);
    outline: none;
    padding-left: 2em;
    font-size: var(--default-font-size);
}
.request-box-input{
    width: 100%;
}

.search-box-buttons {
    display: flex;
    width: 20%;
    height: 100%;
    gap: 0.5em;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0.5em;
}

.form-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.form-row {
    width: 100%;
    display: flex;
    gap: 0.5em;
    padding: 0.25em;
}
.form-input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 40px;
    margin: 0.5em 0;
}

.form-input-row {
    width: 100%;
    min-height: 2.5em;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.form-input-row-field,
.form-input-field {
    width: 100%;
    height: 40px;
    background-color: var(--light-gray-colour);
    border: 1px solid var(--light-gray-border-colour);
    padding: 0.5em;
    border-radius: var(--field-border-radius);
    font-size: var(--default-font-size);
    font-family: var(--default-font-family);
}

.form-input-row-error,
.form-input-error{
    width: 100%;
    color: var(--del_icon_color);
    text-align: right;
}
.form-input-row-error{
    grid-column: 1 / 3;
}

.form-input-row-field,
.form-input-row-check,
.form-input-field:focus {
    background-color: #ffffff;
    border: 1px solid var(--light-gray-border-colour);
    outline: none;
}
.form-input-row-check{
    margin-left: 0.5em;
    width: 1.25em;
    height: 1.25em;
    justify-self: left;
}

.form-input-row-field{
    height: 2em;
    margin: 0.25em;
}
.form-input-list-title,
.form-row-input-label,
.form-input-label {
    width: 100%;
    font-size: var(--default-font-size);
    font-family: var(--default-font-family);
}

.form-input-label{
    height: 40px;
}

.input-textarea-field {
    width: 100%;
    height: 100px;
    resize: none;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--field-border-radius);
}

.form-input-list {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 0.25em;
}

.form-input-list ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding-top: 0.25em;
}

.form-input-list ul li {
    list-style: none;
    height: 2em;
}
.form-input-list ul li input{
    margin-right: 0.5em;
}